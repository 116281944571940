<template>
  <div class="allowed-times-container">
    <h3 class="time-slots">{{$t('CHOOSE TIME SLOT')}}</h3>
    <v-divider class="inner-divider"></v-divider>
    {{time}}
    <ul class="donate-now" v-if="selectedDateOBJ">
      <li v-for="(item, index) in selectedDateOBJ.availableSlots" :key="index">
        <input
          type="radio"
          :id="item.time"
          name="time"
          v-model="time"
          :value="item.time"
        />
        <label :for="item.time">{{ item.time }}</label>
      </li>
    </ul>

    <v-divider class="inner-divider"></v-divider>
    <div class="btn-container">
      <v-btn
          block
          class="pa-0"
        :disabled="!currentDate || !time"
          color="primary"
        @click="create"
      >
        {{$t('DONE')}}
      </v-btn>
    </div>
    <div class="text-center">
      <v-btn outlined  @click="cancelDialog" block  color="primary" class="mt-3 pa-0">{{bookingData.cancelButtonLabel ? bookingData.cancelButtonLabel : 'Close' }}</v-btn>
    </div>

  </div>
</template>

<script>
  import axios from "axios";
  export default {
    name: "allowed-times",
    props: [
      "dates",
      "currentDate",
      "WARedirectNo",
      "FBRedirectPageNo",
      "TWRedirectPageNo",
      "channel",
      "bookingData"
    ],

    components: {},

    data: () => ({
      times: null,
      time: '',
    }),
    methods: {
      create() {
        this.$store.dispatch('pushMessage', {
          slug: 'text',
          payload: `${this.currentDate} ${this.time}`,
          type: 'user',
          lastMessageGroup: true
        })
        this.$parent.$emit('postBack',`${this.currentDate} ${this.time}`)
      },
      cancelDialog() {
        this.$parent.$emit('postCustom', {
          type: 'message',
          text: "cancel_all_dialogs",
          cancelRedirectBlock: this.bookingData.cancelRedirectBlock,
        })
      }
    },

    computed: {
      selectedDateOBJ: function () {
        return this.dates.find((e) => e.date === this.currentDate);
      },
    },
    watch: {
      currentDate: function () {
        this.time = '';
      },
    },
  };
</script>
<style lang="scss">
  .allowed-times-container {
    padding: 0px 30px 30px 30px;
    ::-webkit-scrollbar {
      width: 1px;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(197, 197, 197, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgb(199, 199, 199);
      outline: 1px solid rgb(223, 223, 223);
    }
    .time-slots {
      color: var(--primaryColor);
      font-size: 18px;
      margin-bottom: 10px;
    }
    .donate-now {
      max-height: 500px !important;
      overflow-y: scroll;
      list-style-type: none;
      margin: 10px 0;
      padding: 0 !important;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
        width: 25%;
        position: relative;
        margin:0;
      }

      label,
      input {
        padding: 10px !important;
        display: block;
      }

      input[type="radio"] {
        opacity: 0.011;
        z-index: 100;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      input[type="radio"]:checked + label {
        background: var(--primaryColor);
        color: white;
      }

      label {
        padding: 5px;
        //border: 1px solid var(--primaryColor);
        border-radius: 8px;

        text-align: center;
        padding: 7px 0;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        z-index: 90;
        &:hover {
          background: rgb(231, 231, 245);
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      .create {
        width: 100%;
        border-radius: 8px;
        font-size: 16px;
        padding: 10px 20px;
        outline: none;
        margin: 3px;

        border: 1px solid var(--primaryColor);
        background-color: var(--primaryColor);
        color: white;
      }
      .disabled {
        background-color: var(--primaryColor);
        border: none;
        opacity: 0.1;
      }
    }
  }

  @media screen and (max-width: 599px) {
    .allowed-times-container {
      padding: 0 24px;
      .inner-divider {
        display: none;
      }
      .donate-now {
        height: 200px !important;
        li {
          width: calc((100% - 30px) / 3);
        }
      }
      // .btn-container {
      //   margin-top: 25px;
      // }
    }
  }

  @media screen and (max-width: 340px) {
    .allowed-times-container {
      .donate-now {
        height: 200px !important;
        label {
          font-size: 10px !important;
          padding: 12px 0 !important;
        }
      }
    }
  }

</style>
