<template>
    <div class="widget-actions">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "widgetActions"
    }
</script>

<style  lang="scss">

    .widget-action-trigger {
        position: relative;
    }
    .widget-actions {
        position: absolute;
        z-index: 0;
        bottom: 48px;
        right: 24px;
        display: flex;
        flex-direction: column;
        width: 7%;
    }
    .nds{
        border-radius: 50%;
        z-index: 300;
        transform:  scale(0);
        padding: 5px;
        height: 30px;
        width: 30px;
        cursor: pointer;
        box-shadow:  0px 0px 9px 0px #eee;
        margin-top: 8px;
        display: flex;
        align-items: center;
        background: var(--primaryColor) !important;;
        .mdi {
            color: white !important;
        }

    }

    .nd1{
        animation-delay: 0.2s;
        animation: bounce-out-nds 0.3s linear;
        animation-fill-mode:  forwards;
    }
    .nd2{
        animation-delay: 0.15s;
        animation: bounce-out-nds 0.15s linear;
        animation-fill-mode:  forwards;
    }

    @keyframes bounce-nds{
        from {opacity: 0;}
        to {opacity: 1; transform: scale(1);}
    }

    @keyframes bounce-out-nds{
        from {opacity: 1; transform: scale(1);}
        to {opacity: 0; transform: scale(0);}
    }

    .activeActions .nds{

        animation: bounce-nds 0.1s linear;
        animation-fill-mode:  forwards;
    }

    .activeActions .nd1{
        animation-delay: 0.15s;
    }
    .activeActions .nd2{
        animation-delay: 0.08s;
    }
    .activeActions .nd3{
        animation-delay: 0.2s;
    }


    .activeActions .plug-trigger {
        transform: rotate(315deg);
    }

    .tooltip {
        position: relative;
        display: inline-block;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 100px;
        background-color: whitesmoke;
        text-align: center;
        border-radius: 8px;
        padding: 5px 0;
        font-size: 11px;
        /* Position the tooltip */
        top: 50%;
        transform: translateY(-50%);
        right:32px;
        position: absolute;
        z-index: 1;

    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
</style>
