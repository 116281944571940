<template>
  <div class="text botter-conversation">
    <div class="d-flex align-end botter-image" :class="getAlignment()">
      <user-chat-image :sender="con.sender" v-if="con.type === 'bot' && lastMessageGroup"/>
      <iframe width="100%"  v-if="con.url.includes('youtube')" :src="`//www.youtube.com/embed/${common.get_yt_iframe_from_url(con.url)}`" frameborder="0" allowfullscreen></iframe>
      <video width="300" controls v-else>
        <source :src="con.url" type="video/mp4">
      </video>
    </div>
    <div v-if="lastMessageGroup" class="chat-date d-flex" :class="getAlignment()">{{common.formatDate(con.date)}}</div>
  </div>
</template>

<script>
  import userChatImage from "./common/userChatImage";
  import common from "../../utils/common";
  export default {
    name: "conversation",
    components: {
      userChatImage
    },
    props: {
      con: {
        type: Object,
        default: ()=>{
          return {}
        }
      },
      index: {
        type: Number,
        default: ()=> {
          return 0
        }
      }
    },
    data() {
      return {
        common
      }
    },
    methods: {
      getAlignment() {
        if (this.con.type==='user') {
          return 'justify-end'
        } else if(this.con.type==='bot') {
          return 'justify-start'
        }
      },
      getColor() {
        if (this.con.type==='user') {
          return 'botter-user-text'
        } else if(this.con.type==='bot') {
          return 'botter-bot-text'
        }
      }
    },
    computed: {
      lastMessageGroup () {
        return this.$store.state.messages.conversationList[this.index].lastMessageGroup
      }
    }
  }
</script>

<style>
 iframe {
   border-radius: 5px;
 }
</style>
