<template>
  <div class="text botter-conversation botter-typing-card">
    <div class="d-flex align-end" :class="getAlignment()">
      <user-chat-image :sender="con.sender" v-if="con.type === 'bot' && lastMessageGroup"/>
      <div  class="botter-text botter-typing" :class="getColor()">
        <div class="typing-indicator">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import userChatImage from "./common/userChatImage";
  import common from "../../utils/common";
  export default {
    name: "textData",
    components: {
      userChatImage
    },
    props: {
      con: {
        type: Object,
        default: ()=>{
          return {}
        }
      },
      index: {
        type: Number,
        default: ()=> {
          return 0
        }
      }
    },
    data() {
      return {
        common
      }
    },
    methods: {
      getAlignment() {
        if (this.con.type==='user') {
          return 'justify-end'
        } else if(this.con.type==='bot') {
          return 'justify-start'
        }
      },
      getColor() {
        if (this.con.type==='user') {
          return 'botter-user-text'
        } else if(this.con.type==='bot') {
          return 'botter-bot-text'
        }
      }
    },
    computed: {
      lastMessageGroup () {
        return this.$store.state.messages.conversationList[this.index].lastMessageGroup
      }
    }
  }
</script>

<style lang="scss" scoped>
  .botter-typing {
    width: 20%;
    padding: 10px;
  }
  .typing-indicator {
    $ti-color-bg: #E6E7ED;
    will-change: transform;
    width: auto;
    border-radius: 50px;
    padding: 3px;
    display: table;
    margin: 0 auto;
    position: relative;
    animation: 2s bulge infinite ease-out;

    span {
      height: 8px;
      width: 8px;
      float: left;
      margin: 0 1px;
      background-color: var(--primaryColor);
      display: block;
      border-radius: 50%;
      opacity: 0.4;
      @for $i from 1 through 3 {
        &:nth-of-type(#{$i}) {
          animation: 1s blink infinite ($i * .3333s);
        }
      }
    }
  }

  @keyframes blink {
    50% {
      opacity: 1;
    }
  }

  @keyframes bulge {
    50% {
      transform: scale(1.05);
    }
  }
</style>
