<template>
  <v-app>
    <botter-widget></botter-widget>
    <dynamic-css/>
  </v-app>
</template>

<script>
  import botterWidget from "./components/botterWidget";
  import dynamicCss from "./components/dynamicCSS";
  export default {
    name: 'App',
    components: {
      botterWidget,
      dynamicCss
    },
    data() {
      return {
        window: {}
      }
    },
  }
</script>

<style>
  @import "style/helpers.scss";
  @import "style/normalize.scss";
  @import "style/vuetify.scss";
  @import "style/components/steppers.scss";
</style>
