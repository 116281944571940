<template>
  <div class="text botter-conversation">
    <div class="d-flex align-end" :class="getAlignment()">
      <user-chat-image :sender="con.sender" v-if="con.type === 'bot' && lastMessageGroup"/>

        <div  class="botter-gallery">
          <carousel
            :paginationEnabled="false"
            :perPage="1.5"
            :scrollPerPage="false"
            :navigationEnabled="true"
            navigationNextLabel="<svg xmlns='http://www.w3.org/2000/svg' width='8.344' height='13.203' viewBox='0 0 8.344 13.203'>
    <g id='prefix__noun_right_1786453' transform='translate(-30.656 -19.391)'>
        <g id='prefix__Group_14' data-name='Group 14' transform='translate(30.656 19.391)'>
            <path id='prefix__Path_5' d='M32.152 971.753l-1.5 1.557 5.23 5.048-5.23 5.041 1.5 1.557 6.039-5.823.809-.775-.809-.782z' data-name='Path 5' transform='translate(-30.656 -971.753)' style='fill:#fff'/>
        </g>
    </g>
</svg>
"
            navigationPrevLabel="<svg xmlns='http://www.w3.org/2000/svg' id='prefix__noun_right_1786453' width='8.344' height='13.203' viewBox='0 0 8.344 13.203'>
    <g id='prefix__Group_14' data-name='Group 14'>
        <path id='prefix__Path_5' d='M37.5 971.753l1.5 1.557-5.23 5.048L39 983.4l-1.5 1.557-6.039-5.823-.809-.775.809-.782z' data-name='Path 5' transform='translate(-30.656 -971.753)' style='fill:#fff'/>
    </g>
</svg>
"
          >
            <slide
              :data-index="index"
              :key="index"
              v-for="(slide ,index) in con.data"
             >
              <div class="botter-gallery-item">
                <div v-if="slide.media_type === 'video'">
                  <iframe width="100%"  v-if="slide.url.includes('youtube')" :src="`//www.youtube.com/embed/${common.get_yt_iframe_from_url(slide.url)}`" frameborder="0" allowfullscreen></iframe>
                  <video width="300" controls v-else>
                    <source :src="slide.url" type="video/mp4">
                  </video>
                </div>
                <img :src="slide.url" alt="" v-else>
                <h3 :class="common.getTextAlignment(slide.header)"> {{slide.header}}</h3>
                <p :class="common.getTextAlignment(slide.desc)">{{slide.desc}}</p>
                <div class="botter-gallery-buttons-list">
                  <div  v-for="(btn,btnIndex) in slide.actions" :key="btnIndex" class="mt-3">
                    <a :href="`tel:${btn.value}`" @click="selectCard(index)" v-if="btn.type === 'call'" target="_blank" :class="{'button-disabled': inputDisabled}">
                      <b-btn>
                        {{btn.title}}
                      </b-btn>
                    </a>
                    <a :href="btn.value" @click="selectCard(index)" target="_blank" v-if="btn.type === 'openUrl'" :class="{'button-disabled': inputDisabled}">
                      <b-btn>
                        {{btn.title}}
                      </b-btn>
                    </a>
                    <div @click="postBack(btn,index)" :class="{'button-disabled': inputDisabled || (clicked.includes(index) && $store.state.core.widgetParameters.buttonsSingleClick)}">
                      <b-btn v-if="btn.type === 'postBack'">
                        {{btn.title}}
                      </b-btn>
                    </div>
                    <div @click="callEvent(btn.value)" :class="{'button-disabled': inputDisabled}">
                      <b-btn v-if="btn.type === 'event'">
                        {{btn.title}}
                      </b-btn>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>

    <div v-if="lastMessageGroup" class="chat-date d-flex" :class="getAlignment()">{{common.formatDate(con.date)}}</div>
<!--    <div class="botter-buttons-list">-->
<!--      <div class="d-inline-block" v-for="(btn,index) in con.actions" :key="index">-->
<!--        <a :href="`tel:${btn.value}`" v-if="btn.type === 'call'">-->
<!--          <b-btn>-->
<!--            {{btn.title}}-->
<!--          </b-btn>-->
<!--        </a>-->
<!--        <a :href="btn.value" target="_blank" v-if="btn.type === 'openUrl'">-->
<!--          <b-btn>-->
<!--            {{btn.title}}-->
<!--          </b-btn>-->
<!--        </a>-->
<!--        <div @click="postBack(btn.value)">-->
<!--          <b-btn v-if="btn.type === 'postBack'">-->
<!--            {{btn.title}}-->
<!--          </b-btn>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>

<script>
  import userChatImage from "./common/userChatImage";
  import { Carousel, Slide } from 'vue-carousel';
  import common from "../../utils/common";
  export default {
    name: "textData",
    components: {
      userChatImage,
      Carousel,
      Slide
    },
    props: {
      con: {
        type: Object,
        default: ()=>{
          return {}
        }
      },
      index: {
        type: Number,
        default: ()=> {
          return 0
        }
      }
    },
    data() {
      return {
        common,
        clicked: []
      }
    },
    methods: {
      getAlignment() {
        if (this.con.type==='user') {
          return 'justify-end'
        } else if(this.con.type==='bot') {
          return 'justify-start'
        }
      },
      getColor() {
        if (this.con.type==='user') {
          return 'botter-user-text'
        } else if(this.con.type==='bot') {
          return 'botter-bot-text'
        }
      },
      postBack(value,index) {
        this.$emit('postBack',value)
        this.clicked.push(index)
        this.selectCard(index)
      },
      selectCard (index) {
        // let selectedCard = this.con.data[index]
        // this.con.data = []
        // this.con.data.push(selectedCard)
      },
      callEvent(value) {
        this.$emit('postEvent',value)
        try {
          webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify( {msg_code: value}));
        }
        catch (e) {
          // alert(e.message)
        }
      }
    },
    computed: {
      lastMessageGroup () {
        return this.$store.state.messages.conversationList[this.index].lastMessageGroup
      },
      inputDisabled() {
        return this.$store.state.messages.inputDisabled
      },
    }
  }
</script>

<style lang="scss">
  .botter-buttons-list {
  }
  .botter-buttons-list > div {
    margin-right: 10px;
    margin-top: 10px;
  }
  .botter-gallery {
    margin-top: 10px;
    width: 100%;
    .botter-gallery-item {
      background: #eaf0f7;
      padding: 10px;
      margin-right: 5px;
      border-radius: 5px;
      height: 100%;
      display: flex;
      flex-direction: column;
      img {
      }
      h3 {
        margin: 8px 0 0 0;
        font-size: 14px;
        font-weight: bold;
      }
      p {
        font-weight: normal;
        font-size: 12px;
      }
      .botter-gallery-buttons-list {
        margin-top: auto;
        .botter-btn {
          background: white !important;
          color: inherit !important;
          border: none;
          display: block;
        }
      }
    }
    .VueCarousel-slide {
      height: auto;
    }


  }

  .VueCarousel-navigation-button {
    background-color: var(--primaryColor) !important;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    outline: none !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 75px !important;
    visibility: hidden;
    transition: .5s ease;
  }

  .botter-gallery:hover  .VueCarousel-navigation-button{
    visibility: visible;
  }

  .VueCarousel-navigation-next {
    right: 40px !important;
  }

  .VueCarousel-navigation-prev {
    left: 40px !important;
  }



  .VueCarousel-navigation--disabled {
    display: none !important;
  }

</style>
