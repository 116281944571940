<template>
  <div class="calendar-container" :style="userStyle">
    <v-skeleton-loader
        class="mx-auto pt-4"
        max-width="300"
        type="card"
        v-if="loading"
    ></v-skeleton-loader>
    <v-row justify="center" no-gutters v-else>
      <v-col cols="12"  class="mt-2 pb-0"   v-if="dates.length">
        <date-picker
          :availableDates="dates.map((e) => e.date)"
          :currentDate="currentDate"
          @input="currentDate = $event"
          :header="header"
          :desc="desc"
          :mainColor="mainColor"
          :logo="logo"
        />
      </v-col>
      <v-col cols="12" md="3" sm="4" class="outer-divider-container">
        <v-divider class="outer-divider"></v-divider>
      </v-col>
      <v-col cols="12" >
        <allowed-times
          :currentDate="currentDate"
          :dates="dates"
          :booking-data="bookingData"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import datePicker from "./datePicker";
  import allowedTimes from "./allowedTimes";
  import axios from "axios";
  export default {
    name: "calendar",
    components: {
      datePicker,
      allowedTimes,
    },
    props: {
      bookingData: {
        type: Object,
        default: ()=> {
          return {}
        }
      }
    },
     data() {
        return {
          currentDate: null,
          dates: [],
          channel: "",
          WARedirectNo: null,
          FBRedirectPageNo: null,
          TWRedirectPageNo: null,
          header: "",
          desc: "",
          mainColor: "",
          logo: "",
          loading: false
        }
     },
    mounted() {
      if (this.bookingData.request.type === "get") {
        this.getDynamicApi(this.bookingData.request, this.bookingData.request.headers);
      } else {
        this.postDynamicApi(this.bookingData.request, this.bookingData.request.headers);
      }
    },
    methods: {
      getDynamicApi(req, headers) {
        this.loading = true
        axios.get(req.url, { params: req.user_attribute, headers })
          .then((response) => {
            this.dates = response.data;
            this.currentDate = response.data[0].date;
            this.loading = false
          })
          .catch((e) => {
          })
        .finally(()=>{

        })

      },
      postDynamicApi(req, headers) {
        this.loading = true
        axios
          .post(req.url, req.user_attribute, { headers })
          .then((response) => {
            this.dates = response.data;
            this.currentDate = response.data[0].date;
            this.loading = false
          })
          .catch((e) => {

          })
            .finally(()=>{

            })
      },
      resetLoader() {

      }
    },
    computed: {
      userStyle() {
        return {
          "--main-color": this.mainColor,
        };
      },
    },
  };
</script>

<style lang="scss">
  .calendar-container {
    .outer-divider-container {
      display: none;
    }
  }

  @media screen and (max-width: 599px) {
    .calendar-container {
      .outer-divider-container {
        display: block;
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        padding-top: 0;
        padding: 0;
      }
    }
  }

</style>
