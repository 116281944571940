import Vue from 'vue'
import axios from 'axios'


let apiBaseUrl = document.getElementById('botter-widget').getAttribute('base-api-url') ?
  document.getElementById('botter-widget').getAttribute('base-api-url') :
  process.env.VUE_APP_BASE;


let apiSession = document.getElementById('botter-widget').getAttribute('session-manager-api-url') ?
  document.getElementById('botter-widget').getAttribute('session-manager-api-url') :
  process.env.VUE_APP_SESSION_MANAGER_BASE_URL

let apiBaseUpload = document.getElementById('botter-widget').getAttribute('upload-api-url') ?
  document.getElementById('botter-widget').getAttribute('upload-api-url') :
  process.env.VUE_APP_BASE_UPLOAD;

let botId = document.getElementById('botter-widget').getAttribute('bot_id');



Vue.prototype.$http = axios.create({
    baseURL: apiBaseUrl,
})


Vue.prototype.$http_upload = axios.create({
    baseURL: apiBaseUpload,
    headers: {
        "Content-Type": "multipart/form-data; boundary=MultipartBoundry"
    }
})

Vue.prototype.$http_session = axios.create({
    baseURL: apiSession,
})

Vue.prototype.$http_session = axios.create({
    baseURL: apiSession,
})

Vue.prototype.$http_without_base = axios.create({

})

export const http_session = Vue.prototype.$http_session
