<template>
  <div class="botter-offline-message" v-if="$store.state.core.widgetParameters.offLineMessageAppearance !== 'off'">
    <div class="botter-offline-message-wrapper">
<!--      <div class="botter-offline-message-avatar" >-->
<!--        <img :src="$store.state.core.widgetParameters.botAvatar" alt="">-->
<!--      </div>-->
      <div class="botter-offline-message-close" @click="closeOfflineMessage">
        <img  :src="$store.state.core.widgetParameters.closeBubbleImage" alt="">
      </div>
      <div class="botter-offline-message-body">
        <p>{{$store.state.core.widgetParameters.offlineMessage}}</p>
      </div>
    </div>
    <span class="botter-offline-message-icon">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="106.79" height="93.799" viewBox="0 0 106.79 93.799">
        <defs>
          <filter id="Combined-Shape-Copy" x="0" y="0" width="106.79" height="93.799" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="12" result="blur"/>
            <feFlood flood-opacity="0.102"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="Group" transform="translate(36 34)">
          <path id="Combined-Shape-Copy-2" data-name="Combined-Shape-Copy" d="M18.367,0A36.658,36.658,0,0,1,34.584,21.12L0,21.285S22.059,17.227,18.367,0Z" transform="translate(34.712 21.799) rotate(180)" fill="#eaf0f6" stroke="#eaf0f6" stroke-width="1" fill-rule="evenodd"/>
          <g transform="matrix(1, 0, 0, 1, -36, -34)" filter="url(#Combined-Shape-Copy)">
            <path id="Combined-Shape-Copy-3" data-name="Combined-Shape-Copy" d="M18.445,0A36.355,36.355,0,0,1,34.621,20.912l.169.727L.063,21.8,0,21.319S22.136,17.227,18.445,0Z" transform="translate(70.79 55.8) rotate(180)" fill="#fff" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
    </span>
  </div>
</template>

<script>
  import messages from "../utils/messages";
  import store from "../store";
  export default {
    name: "offlineMessage",
    mounted() {
      let offLinesMessageStatus = localStorage.getItem('offLinesMessageStatus')
      if (offLinesMessageStatus !== 'closed' || this.$store.state.core.widgetParameters.offLineMessageAppearance !== 'once') {
          let element = document.getElementsByClassName('botter-offline-message')[0]
          setTimeout(()=>{
              element.classList.toggle('botter-offline-message-active')
            window.top.postMessage('toggleOfflineMessage', '*')
          },3000)
      }
    },
    methods: {
      closeOfflineMessage() {
        let element = document.getElementsByClassName('botter-offline-message')[0]
        element.classList.remove('botter-offline-message-active')
        window.top.postMessage('toggleOfflineMessage', '*')
          if (this.$store.state.core.widgetParameters.offLineMessageAppearance === 'once') {
            localStorage.setItem('offLinesMessageStatus', 'closed')
          }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .botter-offline-message {
    position: fixed;
    right: 25px;
    z-index: 1000;
    bottom: -400px;
    max-width: 255px;
    transition: .5s;
    .botter-offline-message-wrapper {
      position: relative;
      .botter-offline-message-avatar {
        position: absolute;
        background: #D9F4F5;
        top: 0;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 35px;
        height: 35px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 25px;
        }
      }
      .botter-offline-message-body {
        background: white;
        box-shadow: rgba(0, 0, 0, 0.16) 3px 3px 10px;;
        padding: 13px;
        border-radius: 8px;
        font-weight: bold;
        p {
          color: black;
        }
      }
    }
  }
  .botter-offline-message-active {
    bottom: 100px;
  }
  .botter-offline-message-icon {
    position: absolute;
    bottom: -67px;
    right: 7px;
  }
  .botter-offline-message-close {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    padding: 5px;
    background: #aaa;
    justify-content: center;
    border-radius: 100%;
    transform: translate(-50%,-50%);
  }

</style>
