<template>
  <div>
    <label for="">{{field.label}}</label>
    <v-textarea outlined color="primary" :rules="rules" v-model="formData[field.key]" height="70"></v-textarea>
  </div>
</template>

<script>
  export default {
    name: "b-text-field",
    props: {
      field: {
        type: Object,
        default: ()=> {
          return {}
        }
      },
      formData: {
        type: Object,
        default: ()=> {
          return {}
        }
      }
    },
    data () {
      return {

      }
    },
    computed: {
      rules() {
        let pattern
        if (this.field.regex) {
          pattern = new RegExp(this.field.regex)
        }
        return [
          this.field.required? v => !!v || this.field.errorMessage : true,
          this.field.regex ? v => pattern.test(v) || this.field.errorMessage : true
        ]
      }
    }
  }
</script>

<style scoped>

</style>
