<template>
    <div class="text botter-conversation">
        <div class="d-flex align-end" :class="getAlignment()">
            <user-chat-image :sender="con.sender" v-if="con.type === 'bot' && lastMessageGroup"/>
            <div  class="botter-image">
                <a :href="con.url" target="_blank">
                    <img :src="con.mediaUrl || con.url" alt="">
                </a>
            </div>
        </div>
        <div v-if="lastMessageGroup" class="chat-date d-flex" :class="getAlignment()">{{common.formatDate(con.date)}}</div>
    </div>
</template>

<script>
    import userChatImage from "./common/userChatImage";
    import common from "../../utils/common";
    export default {
        name: "conversation",
        components: {
            userChatImage
        },
        props: {
            con: {
                type: Object,
                default: ()=>{
                    return {}
                }
            },
            index: {
                type: Number,
                default: ()=> {
                    return 0
                }
            }
        },
        data() {
            return {
                common
            }
        },
        methods: {
            getAlignment() {
                if (this.con.type==='user') {
                    return 'justify-end'
                } else if(this.con.type==='bot') {
                    return 'justify-start'
                }
            },
            getColor() {
                if (this.con.type==='user') {
                    return 'botter-user-text'
                } else if(this.con.type==='bot') {
                    return 'botter-bot-text'
                }
            }
        },
        computed: {
            lastMessageGroup () {
                return this.$store.state.messages.conversationList[this.index].lastMessageGroup
            }
        }
    }
</script>

<style lang="scss">
    .botter-image {
        margin-top: 10px;
        width: auto;
        display: inline-block;
        border-radius: 5px;
        img {
            border-radius: 10px;
        }
    }
</style>
