
export const bookingSteps = {
  state: {
    stepsData: {},
    selectedStepsData: {}
  },
  mutations: {
    'SET_STEPS_DATA' (state, stepsData) {
      state.stepsData = stepsData
    },
  },
  actions: {
    setStepsData ({commit} , stepsData) {
      commit('SET_STEPS_DATA', stepsData)
    },
  }
}
