<template>
  <div v-if="$store.state.core.menus">
    <v-menu top :offset-y="true">
      <template v-slot:activator="{ on, attrs }">

        <img v-bind="attrs"
             v-on="on"  class="mr-3 mt-2 botter-menu-button" src="https://img.icons8.com/android/24/000000/menu.png"/>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in $store.state.core.menus.call_to_actions"
          :key="index"
        >
          <v-list-item-title v-if="item.type === 'postback'" @click="postBack(item)">{{item.title}} </v-list-item-title>
          <v-menu  v-else :offset-x="true" bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title v-bind="attrs" class="d-flex align-center"
                                 v-on="on">{{ item.title }} <v-icon>mdi-chevron-right</v-icon></v-list-item-title>

            </template>

            <v-list>
              <v-list-item
                v-for="(subItem, subIndex) in item.call_to_actions"
                :key="subIndex"
              >
                <v-list-item-title @click="postBack(subItem)">{{ subItem.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: "presistMenu",
    data () {
      return {

      }
    },
    methods: {
      postBack(data) {
        this.$store.dispatch('pushMessage', {
          slug: 'text',
          payload: data.title,
          type: 'user',
          lastMessageGroup: true
        })
        this.$emit('postBack',data)
      }
    }
  }
</script>

<style >
 .v-list-item {
   cursor: pointer;

 }
 .v-list-item:hover {
   cursor: pointer;
   background: #eee;
 }
 .v-list-item .v-list-item__title {
   line-height: 43px !important;
 }
  .botter-menu-button{
    height: 13px;
  }
</style>
