<template>
	<div>
		<div class="botter-weather-card-container">
		<div
	  v-if = "con && con.data"
	  class = "botter-weather-card d-flex align-end  justify-start">
	  <div class="full-width">
	  	<p><b>{{ con.data.area }}</b></p>
		<p>
		   <small>
		  		{{currentDate}}
		   </small>
		</p>
		<div class="v-row full-width">
			<img
			  :src = "con.data.imageUrl"
			  class = "botter-weather-thumbnail" />
			<div class="botter-weather-data v-row">
				<div class = "botter-weather-temp"><b>{{ con.data.temp }}</b></div>
<!--				<div class=""><label><small>&#8457;</small></label></div>-->
				<div class = "full-width text-right">
					<div class = "">{{$t('High')}} {{ con.data.highTemp }}</div>
					<div class = "">{{$t('Low')}} {{ con.data.lowTemp }}</div>
				</div>
			</div>
		</div>
	  </div>
	</div>
	</div>
	</div>
</template>

<script>
import dates from '../../mixins/dates'
export default {

  name: 'weatherData',
  mixins: [ dates ],
  props: {
	con: {
	  type: Object,
	  default: ()=>{
	    return {}
	  }
	},
	index: {
	  type: Number,
	  default: ()=> {
	    return 0
	  }
	}
  },
  data () {
    return {

    }
  },
	computed: {
  	currentDate() {
			var today = new Date();
			var dd = String(today.getDate()).padStart(2, '0');
			var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = today.getFullYear();

			return  mm + '/' + dd + '/' + yyyy;
		}
	}
}
</script>

<style lang="scss" scoped>
.botter-weather-card-container {
	padding-left: 40px !important;
	width: calc( 100% - 40px );
}
.botter-weather-card {
	border: 1px solid $border_color;
	border-radius: $standard_border-radius;
	padding: 12px;
	margin-top: 10px;
}
.botter-weather-thumbnail {
	width: 50px;
	height: 50px;
}
.botter-weather-data {
	padding: 4px 14px;
	.botter-weather-temp {
		font-size: 24px;
		margin-top: 6px;
	}
}
</style>
