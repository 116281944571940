import common from "../../utils/common";
export const core = {
  state: {
    botId: '',
    locale: 'en_US',
    startForm: {},
    endForm: {},
    menus: {},
    introButtons: [],
    widgetParameters: {},
    attributes: [],
    externalAttributes: {},
    introDataLoader: false
  },
  mutations: {
    'SET_BOT_ID' (state, botId) {
      state.botId = botId
    },
    'SET_LOCALE' (state, locale) {
      state.locale = locale
    },
    'SET_START_FORM' (state, startForm) {
      state.startForm = startForm
    },
    'SET_END_FORM' (state, endForm) {
      state.endForm = endForm
    },
    'SET_MENUS' (state,menus) {
      state.menus = menus
    },
    'SET_INTRO_BUTTONS' (state,introButtons) {
      state.introButtons = introButtons
    },
    'SET_WIDGET_PARAMETERS' (state, widgetParameters) {
      state.widgetParameters = widgetParameters
    },
    'SET_ATTRIBUTES' (state,attributes) {
      state.attributes = attributes
    },
    'SET_EXTERNAL_ATTRIBUTES' (state,externalAttributes) {
      state.externalAttributes = externalAttributes
    },
    'SET_INTRO_DATA_LOADER' (state, introDataLoader) {
      state.introDataLoader = introDataLoader
    }
  },
  actions: {
    setBotId({commit},botId){
      commit('SET_BOT_ID',botId)
    },
    setLocale({commit}, locale) {
      commit('SET_LOCALE',locale)
    },
    setStartForm({commit},startForm){
      commit('SET_START_FORM',startForm)
    },
    setEndForm({commit},endForm){
      commit('SET_END_FORM',endForm)
    },
    setMenus({commit},menus){
      commit('SET_MENUS',menus)
    },
    setIntroButtons({commit},introButtons){
      commit('SET_INTRO_BUTTONS',introButtons)
    },
    setWidgetParameters({commit},widgetParameters) {
      commit('SET_WIDGET_PARAMETERS',widgetParameters)
    },
    setAttributes ({commit},attributes) {
      commit('SET_ATTRIBUTES',attributes)
    },
    setExternalAttributes ({commit},externalAttributes) {
      commit('SET_EXTERNAL_ATTRIBUTES',externalAttributes)
    },
    setIntroDataLoader ({commit},introDataLoader) {
      commit('SET_INTRO_DATA_LOADER',introDataLoader)
    },
  }
}
