<template>
  <div class="text botter-conversation" v-if="con.payload || con.text">
    <div class="d-flex align-end " :class="getAlignment()">
      <user-chat-image :sender="con.sender" v-if="con.type === 'bot' && lastMessageGroup"/>
      <div class="botter-book-card">

        <img :src="con.extensionImage" alt="" >
        <p class="pa-4" v-html="common.formatText(con.extensionMessage)" :class="common.getTextAlignment(con.extensionMessage)">
        </p>
        <div @click="dialog = true" v-if="!disabled">
          <b-btn > {{con.buttonTitle}} </b-btn>
        </div>


      </div>
      </div>
    <v-dialog v-model="dialog" width="100%" attach="#botter-widget" persistent fullscreen>
      <v-card>
        <booking-steps :card-data="con" @postBack="postBack" @postCustom="postCustom"/>
      </v-card>

    </v-dialog>

    <div v-if="lastMessageGroup" class="chat-date d-flex" :class="getAlignment()">{{common.formatDate(con.date)}}</div>
  </div>
</template>

<script>
import userChatImage from "./common/userChatImage";
import common from "../../utils/common";
import BookingSteps from "./bookingStep/BookingSteps";
export default {
  name: "textData",
  components: {
    BookingSteps,
    userChatImage,
  },
  props: {
    con: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    index: {
      type: Number,
      default: ()=> {
        return 0
      }
    }
  },
  data() {
    return {
      common,
      dateDialog: false,
      date: '',
      disabled: false,
      dialog: false
    }
  },
  methods: {
    getAlignment() {
      if (this.con.type==='user') {
        return 'justify-end'
      } else if(this.con.type==='bot') {
        return 'justify-start'
      }
    },
    getColor() {
      if (this.con.type==='user') {
        return 'botter-user-text'
      } else if(this.con.type==='bot') {
        return 'botter-bot-text'
      }
    },
    postBack(data) {
      this.$emit('postText',JSON.stringify(data))
      this.dialog = false
      this.disabled = true
    },
    postCustom(data) {
      this.$emit('postCustom', data)
      this.dialog = false
      this.disabled = true
    },
  },
  computed: {
    lastMessageGroup () {
      return this.$store.state.messages.conversationList[this.index].lastMessageGroup
    }
  },
  watch: {
    'con.payload': function () {
      let arabic = /[\u0600-\u06FF]/
      let input = document.getElementsByClassName('botter-text')
      for (let i=0; i<input.length;i++) {
        if (arabic.test(this.userInput)) {
          input.style.direction = 'rtl'
        } else  {
          input.style.direction = 'ltr'
        }
      }

    }
  }
}
</script>

<style lang="scss">
.botter-book-card {
  width:100%;
  margin-top: 10px;
  border-radius: 5px;
  background: #EAF0F7;
  overflow: hidden;
  .botter-btn {
    border-radius: 0;
  }
}
</style>
