<template>
  <div>
    <v-date-picker
      :weekday-format="getDay"
      v-model="picker"
      full-width
      first-day-of-week="-1"
      show-adjacent-months
      :allowed-dates="getAllowedDates"
      color="primary"
      ref="date-picker-ref"
      @input="updateDate"
    ></v-date-picker>
  </div>
</template>

<script>
  export default {
    name: "date-picker",

    components: {},
    props: [
      "availableDates",
      "currentDate",
      "header",
      "desc",
      "mainColor",
      "logo",
    ],

    data: () => ({
      picker: null,
    }),

    methods: {
      getDay(date) {
        const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        let i = new Date(date).getDay(date);
        return daysOfWeek[i];
      },
      getAllowedDates(val) {
        return this.availableDates.indexOf(val) !== -1;
      },
      updateDate() {
        this.$emit("input", this.picker);
      },
    },
    watch: {
      currentDate: function () {
        this.picker = this.currentDate;
      },
    },
  };
</script>
<style lang="scss">
  .date-picker-upper-text-container {
    padding-left: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .img-container {
      width: 80px;
      margin-right: 15px;
      img {
        width: 100%;
      }
      // height: 50px;
    }

    .date-picker-upper-text {
      // margin-top: 50px;
      h3 {
        font-size: 22px;
        color: var(--primaryColor);
      }
      p {
        font-size: 16px;
        color: #8d8d8d;
        margin-top: 12px;
        max-width: 435px;
      }
    }
  }
  .v-picker__title {
    display: none;
  }
  .v-date-picker-header {
    justify-content: flex-start !important;
    > button {
      width: 30px !important;
      height: 30px !important;
      border-radius: 3px !important;
      background: rgb(235, 235, 235) !important;
      margin-right: 10px !important;
      color: var(--primaryColor) !important;
      opacity: 0.5;
    }
    .v-date-picker-header__value {
      flex: none !important;
      order: -1 !important;
      div button {
        color: var(--primaryColor) !important;
        font-size: 22px !important;
      }
    }
  }

  .v-date-picker-table {
    height: 320px !important;
    table td button div,
    table th {
      font-size: 16px !important;
    }
    table th {
      color: black !important;
    }
    table tbody td button {
      margin-bottom: 10px !important;
    }
  }

  @media screen and (max-width: 599px) {
    .v-date-picker-table {
      height: 252px !important;
      table td button div,
      table th {
        font-size: 12px !important;
      }
    }
    .v-date-picker-header {
      > button {
        width: 22px !important;
        height: 22px !important;
        margin-left: 10px !important;
      }
      .v-date-picker-header__value {
        flex: 1 !important;
        order: -1 !important;
        text-align: left !important;
        div button {
          font-size: 16px !important;
        }
      }
    }
    .v-date-picker-table tbody tr td {
      button {
        color: var(--primaryColor) !important;
        margin-bottom: 0 !important;
      }
      .v-btn--active {
        color: white !important;
      }
    }
    .date-picker-upper-text-container {
      display: none;
    }
  }

</style>
