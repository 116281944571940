<template>
  <div class="text botter-conversation" v-if="con.payload || con.text">
    <div class="d-flex align-end " :class="getAlignment()">
      <user-chat-image :sender="con.sender" v-if="con.type === 'bot' && lastMessageGroup"/>
      <div  class="botter-text" :class="getColor()">
        <p v-html="common.formatText(con.payload || con.text)" :class="common.getTextAlignment(con.payload || con.text)">
        </p>

      </div>
    </div>
    <v-btn
      outlined
      readonly
      @click="dateDialog = true"
      v-if="!disabled"
      class="mt-3"
      color="primary"
      block
    >{{con.buttonTitle}}{{date}}</v-btn>
    <v-dialog v-model="dateDialog" width="500" persistent>
      <v-card>
        <calender-booking-dialog @postBack="postBack" @postCustom="postCustom" :booking-data="con"/>
      </v-card>

    </v-dialog>
    <div v-if="lastMessageGroup" class="chat-date d-flex" :class="getAlignment()">{{common.formatDate(con.date)}}</div>
  </div>
</template>

<script>
  import userChatImage from "./common/userChatImage";
  import common from "../../utils/common";
  import calenderBookingDialog from "./calender-booking/calenderBookingDialog";
  export default {
    name: "textData",
    components: {
      userChatImage,
      calenderBookingDialog
    },
    props: {
      con: {
        type: Object,
        default: ()=>{
          return {}
        }
      },
      index: {
        type: Number,
        default: ()=> {
          return 0
        }
      }
    },
    data() {
      return {
        common,
        dateDialog: false,
        date: '',
        disabled: false
      }
    },
    methods: {
      getAlignment() {
        if (this.con.type==='user') {
          return 'justify-end'
        } else if(this.con.type==='bot') {
          return 'justify-start'
        }
      },
      getColor() {
        if (this.con.type==='user') {
          return 'botter-user-text'
        } else if(this.con.type==='bot') {
          return 'botter-bot-text'
        }
      },
      postBack(data) {
        this.$emit('postText',data)
        this.dateDialog = false
        this.disabled = true
      },
      postCustom(data) {
        this.$emit('postCustom', data)
        this.dateDialog = false
        this.disabled = true
      },
    },
    computed: {
      lastMessageGroup () {
        return this.$store.state.messages.conversationList[this.index].lastMessageGroup
      }
    },
    watch: {
      'con.payload': function () {
        let arabic = /[\u0600-\u06FF]/
        let input = document.getElementsByClassName('botter-text')
        for (let i=0; i<input.length;i++) {
          if (arabic.test(this.userInput)) {
            input.style.direction = 'rtl'
          } else  {
            input.style.direction = 'ltr'
          }
        }

      }
    }
  }
</script>

<style lang="scss">

</style>
