import Vue from 'vue'
import './utils/cssInjection'
import App from './App.vue'
import store from './store'
import './plugins/axios'


// global components
import bBtn from './components/UIElements/b-btn'

import i18n from './i18n'
import vuetify from './plugins/vuetify';





Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
});




Vue.component('b-btn', bBtn);

Vue.config.productionTip = false

setTimeout(()=> {
  window.__botter_chat__ = new Vue({
    el: "#botter-widget-app",
    render: h => h(App),
    i18n,
    vuetify,
    store
  }).$mount('#app')

},1000)
