<template>
	<div class="botter-receipt-card-container">
		<div class="botter-receipt-card">
			<div class="botter-receipt-card-title">Order Receipt</div>
			<div class="botter-receipt-card-elements botter-receipt-section">
				<div
				  v-for = "(element, e) in con.data.elements"
				  :key = "e"
				  class="botter-receipt-card-element v-row mt-3">
					<img v-if="element.image_url" :src ="element.image_url" class="botter-receipt-thumbnail" />
					<div class="botter-receipt-element-details d-flex align-center">
						<div style="margin-left: 16px;">
							<div class="v-row">
								<p class="botter-receipt-price"><b>{{ element.title }}</b></p>
							</div>
							<div class="v-row">
								<p class="botter-receipt-quantity">
								  {{ `Qty. ${element.quantity} ${element.currency}  ${element.price}` }}
							   </p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="botter-receipt-card-elements full-width botter-receipt-section">
				<div class="v-row">Paid With</div>
				<div class="v-row"><b>{{ con.data.payment_method }}</b></div>
			</div>
			<div class="botter-receipt-card-shipment full-width botter-receipt-section">
				<div class="v-row">Ship To</div>
				<div class="v-row">
					<p><b>
						{{ con.data.address.street_1 }}, 
						{{ con.data.address.city }}, 
						{{ con.data.address.state ?  con.data.address.state + ',': ''}}
						{{ con.data.address.country }}
					</b></p>
				</div>
			</div>
			<div class="v-row botter-receipt-section" style="padding-bottom: 0 !important">
				<div class = "">Total</div>
				<div class="full-width text-right">
				  <b>
				  	{{ `${con.data.summary.total_cost}  ${con.data.currency}` }}
				  </b>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'receiptData',
  props: {
	con: {
	  type: Object,
	  default: ()=>{
	    return {}
	  }
	},
	index: {
	  type: Number,
	  default: ()=> {
	    return 0
	  }
	}
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.botter-receipt-card-container {
	padding-left: 40px !important;
	width: calc( 100% - 40px );
}
.botter-receipt-card {
	border: 1px solid $border_color;
	border-radius: $standard_border-radius;
	padding: 12px;
	margin-top: 10px;
}
.botter-receipt-card-title {
	border-bottom: 1px solid $border_color;
	padding-bottom: 6px;
}
.botter-receipt-thumbnail {
	border-radius: $standard_border-radius;
	width: 80px;
	height: 80px;
}
.botter-receipt-section {
	padding: 8px 6px;
}
.botter-receipt-element-details {
    .botter-receipt-price {
    	font-size: 105%;
    }
    .botter-receipt-quantity {
    	padding-top: 4px;
    }
}
.botter-receipt-card-shipment {
	border-bottom: 1px solid $border_color;
}
</style>
