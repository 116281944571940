import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import widgetParameters from "../utils/widgetParameters";

Vue.use(Vuetify);



export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: `red`,
      },
    },
  },
});
