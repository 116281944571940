<template>
 <div @click="getCurrentLocation" >
  <div class="tooltip">  <v-icon  >mdi-map-marker-outline</v-icon>
    <span class="tooltiptext">{{$t('share location')}}</span>
  </div>

 </div>
</template>

<script>
  export default {
    name: "locationLocator",
    data(){
      return {
        current: {}
      }
    },
    methods: {
       getCurrentLocation () {


        if (navigator.geolocation) {
           navigator.geolocation.getCurrentPosition(position => {

            this.current = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              image: `https://maps.googleapis.com/maps/api/staticmap?center=${position.coords.latitude},${position.coords.longitude}&zoom=18&size=640x400&scale=2&key=${this.$store.state.core.widgetParameters.googleMapApiKey}&markers=${position.coords.latitude},${position.coords.longitude}`
            }

            this.$emit('getLocation', this.current)
          },
           error=>{
            alert(error.message)
           })

        } else {
          alert ("Geolocation is not supported by this browser.");
        }
      },
    }
  }
</script>

<style scoped>

</style>
