import store from "../store";
import common from "./common";
import {http_session} from "../plugins/axios";

export default class messages {
    static async getWidgetParameters() {
        let widgetParameters = {}
        let widgetParametersQuery = common.deserializeQueryUrl()

        const urlParams = new URLSearchParams(window.location.search);
        const botIdQurey = urlParams.get('bot_id');
        const langQuery = urlParams.get('botterLang')

        let widgetParametersTag = document.getElementById('botter-widget').getAttribute('widget_parameters');

        let botId = ''
        if (botIdQurey) {
            botId = botIdQurey
        } else {
            botId = document.getElementById('botter-widget').getAttribute('bot_id');
        }


        if (widgetParametersQuery.chatBotAnswerColor && widgetParametersQuery.bot_id) {
            widgetParameters = widgetParametersQuery
        } else if (widgetParametersTag) {
            widgetParameters = JSON.parse(widgetParametersTag)
        } else {
            widgetParameters = await this.getWidgetDataAPI(botId)
        }

        store.dispatch('setWidgetParameters', widgetParameters)

        let locale = document.getElementById('botter-widget').getAttribute('locale')
        if (langQuery) {
            store.dispatch('setLocale', langQuery)
        } else if (locale) {
            store.dispatch('setLocale', locale)
        } else {
            store.dispatch('setLocale', widgetParameters.lang)
        }

        return widgetParameters
    }

    static async getWidgetDataAPI(botId) {

        const urlParams = new URLSearchParams(window.location.search);
        const langQuery = urlParams.get('botterLang')

        let locale = ''
        if (langQuery) {
            locale = langQuery
        } else {
            locale = document.getElementById('botter-widget').getAttribute('locale');
        }

        await store.dispatch('setLocale', locale)
        let data = {}
        try {
            const response = await http_session.get(`bots/${botId}/widget-settings?lang=${locale}`)
            if (response.data.data) {
                data = this.overrideSettings(response.data.data)
                if (data.position === 'left') {
                    window.top.postMessage('widgetLeft', '*')
                }

                const urlParams = new URLSearchParams(window.location.search);
                const iframeDashboard = urlParams.get('iframe-dashboard');

                if (iframeDashboard === 'true') {
                    data.demoBackground = ''
                    data.position = 'right'
                }

            } else {
                data = {
                    header: "Chat with botter",
                    subHeader: "We’re here to answer your questions about Company. Ask us anything!",
                    offlineMessage: "We’re here to answer your questions about Company. Ask us anything!",
                    botName: "BOTTER",
                    startButtonLabel: "Start now",
                    inputPlaceHolder: "Write a reply",
                    mainColor: '#473FB3',
                    userSideColor: "#473FB3",
                    userSideFontColor: "white",
                    headerColor: "#473FB3",
                    headerFontColor: "white",
                    chatBotAnswerColor: "#eaf0f7",
                    chatBotAnswerFontColor: "#000",
                    bubbleButtonColor: "#473FB3",
                    avatarBackgroundColor: "#eee",
                    typingSound: 'https://notificationsounds.com/soundfiles/58ae749f25eded36f486bc85feb3f0ab/file-sounds-1094-intuition.mp3',
                    messageSound: 'https://notificationsounds.com/soundfiles/dc82d632c9fcecb0778afbc7924494a6/file-sounds-1148-juntos.mp3',
                    logo: "https://dashboard.botter.live/img/logo.8878de79.png",
                    botAvatar: "https://dashboard.botter.live/img/logo.8878de79.png",
                    agentDefaultAvatar: "https://prod-api.botter.live/uploads/2020-08-10/1597053363.png",
                    openBubbleImage: "https://dashboard.botter.live/img/logo.8878de79.png",
                    closeBubbleImage: "https://api.botter.live/uploads/2020-07-24/1595585747.png",
                    logoColorType: "white",
                    botAvatarColorType: "colored",
                    openBubbleImageColorType: "white",
                    avatarType: "circle",
                    font: "Roboto",
                    fontSize: 14,
                    chatBubble: "curvey",
                    direction: 'ltr',
                    position: 'right',
                    lang: 'en_US',
                    hideFaqs: false,
                    hideForms: false,
                    rememberUser: true,
                    facebookLink: "",
                    twitterLink: "",
                    whatsappLink: "",
                    demoBackground: '',
                    botId: ''
                }
            }

        } catch (err) {

        }
        return data
    }

    static getExternalApiData() {

        // let externalAttributes = document.getElementById('botter-widget').getAttribute('botter-data')
        // if (!common.isEmptyObj(externalAttributes)) {
        //   externalAttributes = common.convertObjToArr(JSON.parse(externalAttributes))
        //   store.dispatch('setExternalAttributes', externalAttributes)
        // }

        fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then(({ip}) => {
                this.myIPIsFetched(ip);
            }).catch((error) => {
                //console.error('Error:', error);
                this.myIPIsFetched("0.0.0.0");
            });
    }

    static myIPIsFetched(ip) {
        let externalAttributes = document.getElementById('botter-widget').getAttribute('botter-data')
        if (!common.isEmptyObj(externalAttributes)) {
            externalAttributes = common.convertObjToArr(JSON.parse(externalAttributes))
        } else {
            externalAttributes = [];
        }
        externalAttributes.push({attribute: "user_ip", value: ip});
        store.dispatch('setExternalAttributes', externalAttributes)
    }


    static overrideSettings(data) {
        let overrideData = document.getElementById('botter-widget').getAttribute('widget-settings-override')
        if (overrideData && !common.isEmptyObj(overrideData)) {
            overrideData = JSON.parse(overrideData)
            for (let key in overrideData) {
                if (overrideData.hasOwnProperty(key)) {
                    data[key] = overrideData[key]
                }
            }
        }
        return data
    }


    static inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

}
