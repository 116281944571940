<template>
  <div>
    <p class="secondary--text text-center" v-if="!selectedDate" style="margin-top: 65px">
      {{$t('No appointments available')}}
    </p>
    <p v-if="selectedDate && !availableSlots.length" class="text-center" style="margin-top: 50px">
      {{$t('No appointments available')}}
    </p>
    <v-item-group v-model="selectedTime" class="slots-button" @change="changeTime">
      <v-item v-for="time in availableSlots" :key="time"  v-slot="{ active, toggle }">
        <v-btn @click="toggle"   large :disabled="!time.available" :color="active ? 'primary': 'secondary'" :dark="!!active" outlined :value="time">{{time.time}}</v-btn>
      </v-item>
    </v-item-group>
  </div>
</template>

<script>
export default {
  name: "allowedTimes",
  props: {
    availableSlots: {
      type: Array,
      default: ()=> {
        return []
      }
    },
    selectedDate: {
      type: String,
      default: ()=> {
        return null
      }
    }
  },
  data() {
    return {
      selectedTime: null
    }
  },
  methods: {
    changeTime () {
      this.$emit('changeTime', this.availableSlots[this.selectedTime].time)
    }
  }
}
</script>

<style scoped lang="scss">
.slots-button {
  margin-top: 55px;
  .theme--dark.v-btn {
    color: white !important;
  }
  .v-btn {
    margin: 12px;
  }
}

@media(max-width: 960px) {
  .slots-button {
    margin-top: 0px;
    padding: 0 10px;
    text-align: center;
    .v-btn {
      width: 26%;
      min-width: 0;
    }
  }
}
</style>
