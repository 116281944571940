<template>
  <div>
    <div class="image-uploader" :class="{ smallImageUploader: small, uploaded: url  }">
      <v-dropzone
        :ref="dropZoneId"
        :id="dropZoneId"
        class = "image-uploader-style"
        :options="dropzoneOptions"
        @vdropzone-removed-file="vDzRemove"
        @vdropzone-max-files-exceeded="vDzMaxFilesExceeded"
        @vdropzone-max-files-reached="vDzMaxFilesReached"
        @vdropzone-file-added-manually="vDzMounted"
        @vdropzone-files-added="uploadImage"
      >
      </v-dropzone>
      <div v-if="fileObj.type === 'attachment'" :class="`open-image-dialog-${dropZoneId}`" class="upload-image"><span class="d-block">{{fileObj.fileName}}</span></div>
      <div v-if="!this.url && !loading"  :class="`open-image-dialog-${dropZoneId}`" class="upload-image"><span class="d-block primary--text">{{ dragPlaceholder }}</span></div>
      <div class="image-loader" v-if="loading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate id="loading"
        ></v-progress-circular>
      </div>
    </div>
    <span v-if="!this.url && validation" class="error--text">File Is Required</span>
  </div>

</template>

<script>
  import vDropzone from 'vue2-dropzone'
  export default {
    name: "image-uploader",
    props: {
      url: {
        type: String,
        default: ()=>{
          return ''
        }
      },
      small: {
        type: Boolean,
        default: ()=>{
          return false
        }
      },
      validation: {
        type: Boolean,
        default: ()=>{
          return true
        }
      },
      dragPlaceholder: {
        type: String,
        default: 'Drag and drop ﬁles here or Upload'
      },
      cropFirst: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: 'botter-image-icon'
      },
      type: {
        type: String,
        default: 'image'
      }
    },
    components: {
      vDropzone
    },
    data(){
      return {
        loading: false,
        fileObj: {},
        dropZoneId: `vueDropzone${this._uid}`,
        cropDialog: false,
        cropStep: null,
        aspectRatio: 1,
        cropSaveLoading: false,
        imageToBeCropped: null,
        dropzoneOptions: {
          url: '#',
          thumbnailWidth: 300,
          maxFilesize: 5,
          maxFiles: 2,
          addRemoveLinks: true,
          autoProcessQueue: false,
          previewTemplate: this.getTemplate(),
          dictDefaultMessage: `<span class="${this.icon} primary-text text-lg-2" />`
        },
      }
    },
    mounted() {
      this.loadImg()
      this.$on('onImageUpload', ()=>{
        this.cropDialog = false
      })
    },
    methods: {
      clear() {
        this.$refs[this.dropZoneId].removeAllFiles()
      },
      vDzRemove (file, error, xhr) {
        // console.log('remove')
      },
      vDzMaxFilesExceeded (file) {
        // Disable exceeding maximum allowed files in VueDropzone
        this.$refs[this.dropZoneId].removeFile(file)
      },
      vDzMaxFilesReached () {
          this.$refs[this.dropZoneId].disabled()
      },
      vDzMounted () {

      },
      getTemplate() {
        return `

                    <div id="template-preview">
                            <div class="dz-preview dz-file-preview well" id="dz-preview-template">
                                    <div class="preview"><img data-dz-thumbnail /></div>
                            </div>
                    </div>
                    `
      },
      loadImg() {
        if (this.url) {
          this.$refs[this.dropZoneId].removeAllFiles()
          let file = { size: 123, name: "Image", type: "image/*" };
          this.$refs[this.dropZoneId].manuallyAddFile(file, this.url);
        }
      },
      openDialog() {
        // this.$refs.vueDropzone.removeAllFiles()
      },
      cropAndSave() {
        const result = this.$refs.cropper.getResult()
        const imageDataUrl = result.canvas.toDataURL('image/jpeg')
        const file = this.dataURLtoFile(imageDataUrl, `nameToBe${Date.now()}`)
        this.uploadCroppedImage(file)
      },
      handleCropScenario(file) {
        if(file && file[0]) {
          const reader = new FileReader()
          reader.readAsDataURL(file[0])
          reader.onload = ()=> {
            this.imageToBeCropped = reader.result
            this.cropStep = 'selectAspectRatio'
            this.cropDialog = true
          }
        }
      },
      selectAspectRatio(aspectRatio) {
        this.aspectRatio = aspectRatio
        this.cropStep = 'crop'
      },
      handleCropDialogClose() {
        this.clear()
        this.cropDialog = false
      },
      dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)

        while(n--){
          u8arr[n] = bstr.charCodeAt(n)
        }

        return new File([u8arr], filename, {type:mime});
      },
      uploadCroppedImage(file) {
        this.cropSaveLoading = true
        let formObject = new FormData()
        formObject.append("file", file, "filename" + Date.now())
        this.$http_upload.post('/file/upload',formObject)
          .then(response=>{
            this.$emit('onImageUpload', {url: response.data.url, type: response.data.type , fileName: response.data.name})
            this.$refs[this.dropZoneId].disabled()
          })
          .catch(error=>{

          })
      },
      uploadImage() {
        this.loading = true
        let files = this.$refs[this.dropZoneId].getAcceptedFiles()
        let formObject = new FormData()
        formObject.append("file", files[0])
        this.$http_upload.post('/file/upload',formObject)
          .then(response=>{
            this.fileObj = {url: response.data.url, type: response.data.type , fileName: response.data.name}
            this.$emit('onImageUpload', this.fileObj )
            setTimeout(()=>{
              this.loading = false
            },1000)
          })
          .catch(error=>{

          })
      }
    },
    watch: {
      url() {
        this.loadImg()
      }
    }
  }
</script>

<style lang="scss">
  .image-uploader {
    max-width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #ccc;
    border-radius: 8px;
    background-color: #F4F5F9;
    overflow: hidden;
    img {
      width: 100% !important;
      object-fit: cover !important;
      height: 100%;
      max-width: 100%;
    }
    .upload-image {
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
      top: 50%;
      transform: translate(0,-50%);
      cursor: pointer;

    }
    .replace-image {
      cursor: pointer;
      position: absolute;
      left: 0;
      flex-direction: column;
      align-items: center;
      padding-top: 30%;
      color: white;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(17, 17, 17, .5);
      display: none;
      transition: all .5s ease;
    }
    &:hover .replace-image {
      display: flex;
    }
    .dz-remove {
      display: none;
    }
    .dz-default{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      cursor: pointer;
      z-index: 4;
      padding-bottom: 50px;
      width: 100%;
      text-align: center;
    }
    &:hover .dz-default {
      display: block;
    }
    .image-loader {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      background: rgba(200,200,200,.3);
      top: 0;
    }

  }

  .smallImageUploader {
    width: 100px;
    max-width: 100%;
    height: 100px;
    padding: 10px;
    border: none;
    position: relative;
    border-radius: 20px;
    background: #EBF7FF;
    img {
      width: 100% !important;
      height: 90px !important;
      object-fit: contain !important;
      max-width: 100%;
    }
  }

  .uploaded {
    pointer-events: none;
  }
  .uploaded .dz-default{
    color: white;
    display: none;
  }
  .image-card-placeholder {
    color: mix(#eee, #000000, 80);
  }

</style>
