<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       :width="width" :height="height" viewBox="0 0 150.000000 150.000000"
       preserveAspectRatio="xMidYMid meet">
    <metadata>
      Created by potrace 1.16, written by Peter Selinger 2001-2019
    </metadata>
    <g transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)"
       fill="primary" stroke="none">
      <path d="M110 1184 c0 -238 0 -241 23 -262 26 -24 73 -28 98 -9 8 7 31 41 50
77 177 334 657 359 870 46 218 -321 21 -750 -366 -797 -75 -9 -94 -15 -113
-36 -28 -30 -28 -63 0 -98 21 -27 22 -27 117 -22 158 8 288 67 411 187 74 73
125 153 166 262 25 67 28 85 28 213 1 128 -1 147 -26 213 -39 108 -84 177
-168 262 -161 162 -400 234 -608 184 -83 -21 -214 -81 -266 -123 l-40 -32 -88
88 -88 88 0 -241z"/>
      <path d="M422 797 c-46 -49 -12 -127 54 -127 63 0 98 86 54 130 -28 28 -81 26
-108 -3z"/>
      <path d="M700 800 c-28 -28 -26 -81 3 -108 49 -46 127 -12 127 54 0 63 -86 98
-130 54z"/>
      <path d="M992 813 c-19 -8 -43 -60 -35 -80 22 -62 85 -82 128 -38 30 30 32 64
4 99 -20 25 -61 34 -97 19z"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "botterLogo",
    props: {
      height: {
        type: Number,
        default:()=> {
          return 17
        }
      },
      width: {
        type: Number,
        default:()=> {
          return 17
        }
      }
    }
  }
</script>

<style scoped lang="scss">
svg {
  fill: var(--primaryColor) !important;
}
  .primary-svg{
    fill: var(--primaryColor) !important;
  }
</style>
