import store from '../store'
import messages from "../utils/messages";

export default class BotSocket {

  config= {
    ws_url: '',
    reconnect_timeout: 3000,
    max_reconnect: 30,
    enable_history: false
  }

  options= {
    use_sockets: true,
    enable_history: true
  }

  socket = null
  reconnect_count= 0
  guid= null
  current_user= null
  bot_id = null
  newMessage = {}
  minutes_to_expire_conv = process.env.CONVERSATION_ENDS_AFTER_MINUTES || 30;

  constructor() {
    let ws_url_param = document.getElementById('botter-widget').getAttribute('gateway-socket-url')
    if (store.state.core.widgetParameters.externalSocketBase) {
      this.config.ws_url = store.state.core.widgetParameters.externalSocketBase
    } else if (ws_url_param) {
      this.config.ws_url = ws_url_param
    } else {
      this.config.ws_url = process.env.VUE_APP_BASE_URL
    }

    if (!this.config.ws_url.includes('wss://') && !this.config.ws_url.includes('ws://') ) {
      this.config.ws_url = 'wss://' + this.config.ws_url
    }
    this.bot_id = store.state.core.botId
    this.connectWebsocket()
  }


  connectWebsocket () {
    // Create WebSocket connection.
    this.socket = new WebSocket(this.config.ws_url);
    let connectEvent = 'hello';

    if (this.getCookie('botkit_guid') && !store.state.messages.startNew && this.getCookie('conversation_active') == '1') {
      this.guid = this.getCookie('botkit_guid');
      connectEvent = 'welcome_back';
    } else {
      if (this.getCookie('botkit_guid')) {
        this.guid = this.getCookie('botkit_guid');
      } else {
        this.guid = this.generate_guid();
        this.setCookie('botkit_guid', this.guid, 30);
      }

    }

    if (this.options.enable_history) {
      this.getHistory();
    }

    // Connection opened
    this.socket.addEventListener('open', (event) => {
      store.dispatch('setConnected',true)


      let attributes = []
      let customMessage = ''
      attributes = store.state.core.attributes.concat(store.state.core.externalAttributes)
      //pushing widget lang
      attributes.push({
        attribute: 'lang',
        value: store.state.core.locale.substring(0,2)
      })

      if (store.state.core.widgetParameters.allowParametersFromURL) {
        let URLAttributes = []
        URLAttributes = this.getAttributesFromURL()
        attributes = attributes.concat(URLAttributes)

        let customMessageObject = URLAttributes.find((param)=>{
          return param.attribute === 'type'
        })
        if (customMessageObject) {
          customMessage = customMessageObject.value
        }

      }

      if (store.state.messages.initBlock) {
        connectEvent = ''
      }


      if (store.state.messages.initBlock) {
        connectEvent = '';
      }

    if (this.reconnect_count === 0) {
      this.deliverMessage({
        type: connectEvent,
        user: this.guid,
        channel: 'web',
        user_profile: this.current_user ? this.current_user : null,
        bot_id: this.bot_id,
        attributes: attributes
      });
    }

    if (store.state.messages.initBlock) {
      this.deliverMessage({
        postback: {
          payload: `menu:block_id:{${store.state.messages.initBlock.block}}`,
          title: store.state.messages.initBlock.buttonLabel,
          type: "postback",
        },
        text: `menu:block_id:{${store.state.messages.initBlock.block}}`,
        type: "message",
        user: this.guid,
        channel: 'web',
        user_profile: this.current_user ? this.current_user : null,
        bot_id: this.bot_id,
      })
      store.dispatch('setInitBlock', null)
    }

    setTimeout(()=>{
      this.reconnect_count = 0;
    },5000)


      if (customMessage) {
        setTimeout(()=>{
          this.deliverMessage({
            slug: "text",
            text: customMessage,
            type: "message",
            user: this.guid,
            channel: 'web',
            user_profile: this.current_user ? this.current_user : null,
            bot_id: this.bot_id,
          });
        },2000)
      }

    });

    this.socket.addEventListener('error',  (event)=> {

      store.dispatch('setConnected',false)
    });

    this.socket.addEventListener('close', (event)=> {
      store.dispatch('setConnected',false)
      if (this.reconnect_count < this.config.max_reconnect) {
        this.reconnect_count++

        setTimeout( ()=> {
          this.connectWebsocket();
        }, this.config.reconnect_timeout);
      } else {

      }
    });

    // Listen for messages

    this.socket.addEventListener('message', (event)=> {
      this.setCookieByMinutes('conversation_active', '1', this.minutes_to_expire_conv);
      this.newMessage = {};
      let message = JSON.parse(event.data);
      try {
        this.newMessage = message
      } catch (err) {
        this.newMessage = {
          slug: 'text',
          text: message.text
        }
      }

      if (this.newMessage.history) {
        let historyMessage = {}
        for (let i=0; i<this.newMessage.history.length;i++) {
          historyMessage = {}
          if (this.newMessage.history[i].sender.sender_type === 'BOT' || this.newMessage.history[i].sender.sender_type === 'AGENT' ) {
            historyMessage = {...this.newMessage.history[i]}
            historyMessage.type = 'bot'
          } else {
            historyMessage = {...this.newMessage.history[i]}
            historyMessage.payload = historyMessage.text
            if (!this.newMessage.history[i].slug) {
              historyMessage.slug = 'text'
            }
            historyMessage.type = 'user'
          }

          historyMessage.lastMessageGroup = true
          if (!['hello','welcome_back', 'set_attributes'].includes(this.newMessage.history[i].type)) {
            store.dispatch('pushMessage',historyMessage)
          }

        }
        setTimeout(()=> {
          store.dispatch('setInputDisabled', false)
        },1000)

      } else {
        this.newMessage.type = 'bot'
        this.newMessage.lastMessageGroup = true
        store.dispatch('pushMessage',this.newMessage)
        if (message.slug === 'typing') {
          messages.playSound(store.state.core.widgetParameters.typingSound)
        } else {
          messages.playSound(store.state.core.widgetParameters.messageSound)
        }
      }


      messages.browserNotification()
    });
  }

  sendMessage(message) {
    this.deliverMessage({
      ...message,
      user: this.guid,
      channel: 'web',
      bot_id: this.bot_id
    })
  }

  // sendAttributes() {
  //   let attributes =store.state.core.attributes.concat(store.state.core.externalAttributes)
  //
  //   if (store.state.core.widgetParameters.allowParametersFromURL) {
  //     let URLAttributes = this.getAttributesFromURL()
  //     attributes = attributes.concat(URLAttributes)
  //   }
  //
  //   this.deliverMessage({
  //     type: 'set_attributes',
  //     attributes: attributes,
  //     user: this.guid,
  //     channel: 'web',
  //     bot_id: this.bot_id
  //   })
  // }

  getAttributesFromURL () {
    let query = this.getQueryParams()
    let parameters = []
    for (let key in query) {
      if (query.hasOwnProperty(key)) {
        parameters.push({
          attribute: key,
          value: query[key]
        })
      }
    }
    return parameters
  }

  getQueryParams () {
    let search = location.search.substring(1);
    if (search) {
      return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
    } else {
      return  {}
    }
  }

  deliverMessage(message) {
    this.socket.send(JSON.stringify(message));
  }

  getHistory() {

  }

  s4 () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  generate_guid () {
    let userId = document.getElementById('botter-widget').getAttribute('user_id')
    if (userId) {
      return userId
    } else {
      return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
        this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }
  }

  setCookie(key, value, ttl) {
    key = `${key}_${this.bot_id}`
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + (ttl * 24 * 60 * 60 * 1000)
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  setCookieByMinutes(key, value, ttl) {
    key = `${key}_${this.bot_id}`
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + (ttl * 60 * 1000)
    }
    localStorage.setItem(key, JSON.stringify(item))
  }


  getCookie(key) {
    key = `${key}_${this.bot_id}`
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }



}
