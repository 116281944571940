<template>
  <div>
    <label for="">{{field.label}}</label>
    <v-menu
      ref="menu"
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="formData[field.key]"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formData[field.key]"
          readonly
          outlined
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menu2"
        v-model="formData[field.key]"
        full-width
        color="primary"
        @click:minute="$refs.menu.save(formData[field.key])"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: "b-text-field",
    props: {
      field: {
        type: Object,
        default: ()=> {
          return {}
        }
      },
      formData: {
        type: Object,
        default: ()=> {
          return {}
        }
      }
    },
    data () {
      return {
        menu2: false
      }
    },
    computed: {
      rules() {
        return [
          this.field.required? v => !!v || this.field.errorMessage : true,
          this.field.regex ? v => this.field.regex.test(v) || this.field.errorMessage : true
        ]
      }
    }
  }
</script>

<style scoped>

</style>
