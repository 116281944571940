<template>
    <div class="botter--widget">
        <div class="botter-modal">
            <transition name="fade">



                <div class="botter-modal-wrapper" key="1" v-if="sessionActive">
                    <div class="botter-modal-head">
                        <div class="botters-agents-list">
                            <div class="align-center d-flex">
                                <div class="botter-back-button" @click="backToIntro" v-if="!this.$store.state.core.widgetParameters.hideFaqs || !this.$store.state.core.widgetParameters.hideForms || this.$store.state.core.widgetParameters.displayIntroButtons">
                                    <span class="mdi mdi-chevron-left"></span>
                                </div>
                                <div class="botter-agent-avatar-square ml-2" v-if="this.$store.state.core.widgetParameters.avatarType === 'square'">
                                    <img :src="this.$store.state.core.widgetParameters.logo"  alt="">
                                </div>
                                <div class="botter-agent-avatar d-flex ml-2" v-else>
                                    <img :src="this.$store.state.core.widgetParameters.botAvatar"  alt="">
                                </div>
                                <div class="ml-2 mr-2">
                                    <h5>{{this.$store.state.core.widgetParameters.botName}}</h5>
                                    <div class="botter-agent-status d-flex align-center">
                                        <div class="botter-online-indecator" :class="{'botter-online': connected}">
                                            <span></span>
                                        </div>
                                        <p v-if="connected">{{$t('online')}}</p>
                                        <p v-else>{{$t('back in minutes')}}</p>
                                    </div>
                                </div>
                                <div class="botter-close-mobile" :class="{'botter-close-desktop': $store.state.core.widgetParameters.hideOpenButton}" @click="openModal">
                                    <img class="botter-modal-close-btn" :src="$store.state.core.widgetParameters.closeBubbleImage" alt="">
                                </div>
                            </div>

                        </div>
                    </div>
                    <widget-body
                      :session-active="sessionActive"
                      :modal-active = "modalActive"
                      :on-focus = "modalOnFocus" />
                </div>




                <div class="botter-intro" key="2" v-else-if="!this.$store.state.core.widgetParameters.hideFaqs || !this.$store.state.core.widgetParameters.hideForms || this.$store.state.core.widgetParameters.displayIntroButtons">
                    <div class="botter-intro-header">
                        <img :src="this.$store.state.core.widgetParameters.logo" alt="">
                        <h3 class="botter-intro-title">{{$store.state.core.widgetParameters.header}}</h3>
                        <p>{{$store.state.core.widgetParameters.subHeader}}</p>
                    </div>
                    <div class="botter-close-mobile" @click="openModal">
                        <img class="botter-modal-close-btn" :src="$store.state.core.widgetParameters.closeBubbleImage" alt="">
                    </div>
                    <div class="botter-intro-forms" v-if="$store.state.core.botId">
                        <div class="intro-wrapper">
                            <continue-conversation v-if="common.getCookie('botkit_guid') && common.getCookie('conversation_active') == '1'" @continueConversation="continueSession" />
                            <intro-buttons @start-chat="startSession"/>
                            <intro-form @start-chat="startSession"></intro-form>
                            <faqs v-if="!this.$store.state.core.widgetParameters.hideFaqs"></faqs>
                            <social-links/>
                        </div>
                    </div>
                </div>


            </transition>
        </div>

        <div v-if="!botterStarted && !$store.state.core.widgetParameters.hideOpenButton && !channelsButtonsActive">
            <offline-message v-if="$store.state.core.widgetParameters.offLineMessageAppearance"/>
        </div>

        <open-button @openModal="openModal" @activeChannels="channelsButtonsActive = $event"/>

    </div>
</template>

<script>
    import widgetBody from "./widgetBody";
    import botterLogo from "./UIElements/botterLogo";
    import introForm from './intro-forms/intro-forms'
    import faqs from "./faqs/faqs";
    import socialLinks from "./socialLinks";
    import offlineMessage from "./offlineMessage";
    import common from "../utils/common";
    import widgetParameters from "../utils/widgetParameters";
    import continueConversation from "./continueConversation";
    import openButton from "./openButton";
    import introButtons from './intro-buttons/introButtons'
    export default {
        name: "botter-widget",
        data() {
            return {
                widgetParameters: {},
                sessionActive: false,
                modalActive: false,
                modalOnFocus: false,
                botterStarted: false,
                bodId: '',
                channelsButtonsActive: false,
                common
            }
        },
        components: {
            widgetBody,
            introForm,
            faqs,
            offlineMessage,
            socialLinks,
            continueConversation,
            openButton,
            introButtons
        },
        async mounted() {

            const urlParams = new URLSearchParams(window.location.search);
            const botId = urlParams.get('bot_id') ? urlParams.get('bot_id') : document.getElementById('botter-widget').getAttribute('bot_id')
            await this.$store.dispatch('setBotId',botId)




            this.widgetParameters = await widgetParameters.getWidgetParameters()

            if (this.widgetParameters.chatOpen ) {
              this.openModal()
            }


            window.addEventListener('click', (e) => {   
              if (document.querySelector('.botter-modal').contains(e.target)){
                this.modalOnFocus = true
              } else{
                this.modalOnFocus = false
              }
            });



            this.changeState()
            this.openWidgetExternal()
          this.handleRelativePosition()
        },
        methods: {
            openMultipleChannels() {

            },
            openModal() {

                window.top.postMessage('toggleWidget', '*')
                setTimeout(()=>{
                    document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.toggle('botter-modal-btn-active')
                    document.getElementsByClassName('botter-modal')[0].classList.toggle('botter-modal-active')
                    this.modalActive = document.getElementsByClassName('botter-modal')[0].classList.contains('botter-modal-active')
                },100)

                if (!this.botterStarted) {
                    let introDisabled = (this.$store.state.core.widgetParameters.hideFaqs && this.$store.state.core.widgetParameters.hideForms && !this.$store.state.core.widgetParameters.displayIntroButtons)
                  let formInitiated =common.getCookie('formInitiated')
                    if (
                        (introDisabled || formInitiated)
                        && (common.getCookie('conversation_active') == '1' || introDisabled )) {
                        this.sessionActive = true
                    } else {
                        this.sessionActive = false
                    }
                    this.getFormData()
                }

                this.botterStarted = true
                // wait for the animation, then update focus
                setTimeout(()=> { this.modalOnFocus = this.modalActive }, 500)

            },
            startSession () {
                common.setCookie('formInitiated', true,1/24)
                this.$store.dispatch('setStartNew', true)
                this.$store.dispatch('setSessionActive', true)
                this.sessionActive = true
            },
            continueSession () {
                this.$store.dispatch('setStartNew', false)
                this.$store.dispatch('setSessionActive', true)
                this.sessionActive = true
            },
            endSession () {
                common.delete_cookie('formInitiated')
                common.delete_cookie('botkit_guid')
                this.$store.dispatch('setStartNew', true)
                this.botterStarted = false
                this.sessionActive = false
                this.modalActive = false
                this.$store.dispatch('setConversationList', [])
                document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.remove('botter-modal-btn-active')
                document.getElementsByClassName('botter-modal')[0].classList.remove('botter-modal-active')
            },
            backToIntro() {
                this.$store.dispatch('setInputDisabled', false)
                this.$store.dispatch('setConversationList', [])
                this.sessionActive = false
                this.$store.dispatch('setStartNew', false)
                localStorage.setItem('lastMessageDate', new Date())
            },
            getFormData () {
                this.$store.dispatch('setIntroDataLoader', true)
                this.$http.get(`widget/data/${this.$store.state.core.widgetParameters.lang}?bot_id=${this.$store.state.core.botId}`)
                  .then(response=> {
                        this.$store.dispatch('setMenus',response.data.menu)
                        this.$store.dispatch('setStartForm',response.data.startForm)
                        this.$store.dispatch('setEndForm',response.data.endForm)
                        if (response.data['intro-buttons']) {
                          this.$store.dispatch('setIntroButtons',response.data['intro-buttons'].buttonsList)
                        }
                        this.$store.dispatch('setIntroDataLoader', false)
                    }
                  )
                  .catch(error=> {
                      this.$store.dispatch('setIntroDataLoader', false)
                  })
            },
            openWidgetExternal() {
                let currentIndex = -1
                let elements = document.getElementsByClassName(this.$store.state.core.widgetParameters.externalOpenClass)
                for (let i = 0; i < elements.length; i++) {
                    elements[i].addEventListener('click', ()=>{
                        let botId = elements[i].getAttribute('bot_id')
                        let externalAttributes = elements[i].getAttribute('botter-data')
                        if (currentIndex !== i) {
                            this.endSession()
                        }
                        if (botId) {
                            this.$store.dispatch('setBotId', botId)
                        }
                        if (!common.isEmptyObj(externalAttributes)) {
                            externalAttributes = common.convertObjToArr(JSON.parse(externalAttributes))
                            this.$store.dispatch('setExternalAttributes', externalAttributes)
                        }
                        setTimeout(()=> {
                            this.openModal()
                        },200)

                        currentIndex = i
                    }, false);
                }
            },
            handleRelativePosition () {
              let widget = document.getElementById('app')
              const urlParams = new URLSearchParams(window.location.search);
              const position = urlParams.get('position');
              if (position === 'relative') {
                widget.classList.add('widget-relative')
                setTimeout(()=> {
                  this.openModal()
                  console.log('widget Initiated relative')
                },2000)

              }
            },
            changeState () {
                if ( window.addEventListener ) {
                    window.addEventListener('message', (e)=> {
                        if (e.data.type === 'change-step') {
                            if(e.data.value === 'widget-offline') {
                                document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.remove('botter-modal-btn-active')
                                document.getElementsByClassName('botter-modal')[0].classList.remove('botter-modal-active')
                                this.botterStarted = false
                                this.sessionActive = false
                                this.modalActive = false

                            } else if (e.data.value === 'widget-intro') {
                                document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.add('botter-modal-btn-active')
                                document.getElementsByClassName('botter-modal')[0].classList.add('botter-modal-active')
                                this.botterStarted = true
                                this.sessionActive = false
                                this.modalActive = true
                                this.getFormData()
                            } else if (e.data.value === 'widget-chat') {
                                document.getElementsByClassName('botter-modal-toggle-btn')[0].classList.add('botter-modal-btn-active')
                                document.getElementsByClassName('botter-modal')[0].classList.add('botter-modal-active')
                                this.botterStarted = true
                                this.sessionActive = true
                                this.modalActive = true
                            }
                         }
                     })
                    }
            }
        },
        computed: {
            connected() {
                return this.$store.state.messages.connected
            },
            chatOpen(){
              return this.$store.state.core.widgetParameters.chatOpen
            }
        },
    }
</script>

<style lang="scss" src="../style/components/widgets.scss">
</style>
