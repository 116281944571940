<template>
  <div class="botter-btn" ref="mySlot">
    <slot/>
  </div>
</template>

<script>
  export default {
    name: "b-btn",
    mounted() {
      setTimeout(() => {
          const isArabic = /[\u0600-\u06FF]/.test(this.$refs.mySlot.textContent);
          this.$refs.mySlot.setAttribute('dir', isArabic ? 'rtl' : 'ltr');
      }, 100);
    }
  }
</script>

<style scoped lang="scss">
  .botter-btn {
    cursor: pointer;
    border: 1px solid var(--primaryColor);
    padding: 7px 15px;
    border-radius: 5px;
    color: var(--primaryColor);
    font-size: 13px;
    text-align: center;
  }
  .botter-btn:hover {
   background: #eee;
  }
</style>
