<template>
  <div>
    <label for="">{{field.label}}</label>
    <v-menu
      v-model="menu2"
      :close-on-content-click="false"
      transition="scale-transition"
      max-width="290px"
      min-width="290px"

    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formData[field.key]"
          outlined
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="formData[field.key]" color="primary" @input="menu2 = false"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: "b-text-field",
    props: {
      field: {
        type: Object,
        default: ()=> {
          return {}
        }
      },
      formData: {
        type: Object,
        default: ()=> {
          return {}
        }
      }
    },
    data () {
      return {
        menu2: false
      }
    },
    computed: {
      rules() {
        let pattern
        if (this.field.regex) {
          pattern = new RegExp(this.field.regex)
        }
        return [
          this.field.required? v => !!v || this.field.errorMessage : true,
          this.field.regex ? v => pattern.test(v) || this.field.errorMessage : true
        ]
      }
    }
  }
</script>

<style scoped>

</style>
