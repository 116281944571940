<template>
  <div class="text botter-conversation" v-if="con.payload || con.text">
    <div class="d-flex align-end " :class="getAlignment()">
      <user-chat-image :sender="con.sender" v-if="con.type === 'bot' && lastMessageGroup"/>
      <div  class="botter-text" :class="getColor()">
        <h4 :class="common.getTextAlignment(con.title)"> {{con.title}}</h4>
        <p v-html="common.formatText(con.payload || con.text)" :class="common.getTextAlignment(con.payload || con.text)">
        </p>
        <a :href="con.url" target="_blank">
          <b-btn  class="mt-3">
            {{con.buttonTitle}}
          </b-btn>
        </a>
      </div>
    </div>
    <div v-if="lastMessageGroup" class="chat-date d-flex" :class="getAlignment()">{{common.formatDate(con.date)}}</div>
  </div>
</template>

<script>
import userChatImage from "./common/userChatImage";
import common from "../../utils/common";
export default {
  name: "textData",
  components: {
    userChatImage
  },
  props: {
    con: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    index: {
      type: Number,
      default: ()=> {
        return 0
      }
    }
  },
  data() {
    return {
      common
    }
  },
  methods: {
    getAlignment() {
      if (this.con.type==='user') {
        return 'justify-end'
      } else if(this.con.type==='bot') {
        return 'justify-start'
      }
    },
    getColor() {
      if (this.con.type==='user') {
        return 'botter-user-text'
      } else if(this.con.type==='bot') {
        return 'botter-bot-text'
      }
    }
  },
  computed: {
    lastMessageGroup () {
      return this.$store.state.messages.conversationList[this.index].lastMessageGroup
    }
  },
  watch: {
    'con.payload': function () {
      let arabic = /[\u0600-\u06FF]/
      let input = document.getElementsByClassName('botter-text')
      for (let i=0; i<input.length;i++) {
        if (arabic.test(this.userInput)) {
          input.style.direction = 'rtl'
        } else  {
          input.style.direction = 'ltr'
        }
      }

    }
  }
}
</script>

<style lang="scss">
.botter-text {
  margin-top: 10px;
  padding: 15px;

  display: inline-block;
  border-radius: 5px;
  p {
    word-break: break-word;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 22px;
  }
}
.botter-user-text {
  background: var(--primaryColor);
  color: white;
  width: auto;
  max-width: 70%;
}
.botter-bot-text {
  background: #EAF0F7;
  width: 100%;
  max-width: 100%;
  color: black;
}
</style>
