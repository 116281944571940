<template>
  <div class="text botter-conversation">
    <div class="d-flex align-end " :class="getAlignment()">
      <user-chat-image :sender="con.sender" v-if="con.type === 'bot' && lastMessageGroup"/>
      <div class="full-width">
        <div class="botter-map-card">
          <img :src="con.location.imageUrl" alt="">
          <div class="botter-map-card-desc">
            <h3 :class="common.getTextAlignment(con.location.title)">{{con.location.title}}</h3>
            <p :class="common.getTextAlignment(con.location.description)">{{con.location.description}}</p>
            <a target="_blank" :href="`https://maps.google.com/?q=${con.location.coords.lat},${con.location.coords.long}`">
            <b-btn>
              {{con.location.buttonTitle}}
            </b-btn>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="lastMessageGroup" class="chat-date d-flex" :class="getAlignment()">{{common.formatDate(con.date)}}</div>
  </div>
</template>

<script>
  import userChatImage from "./common/userChatImage";
  import common from "../../utils/common";
  export default {
    name: "travelData",
    components: {
      userChatImage
    },
    props: {
      con: {
        type: Object,
        default: ()=>{
          return {}
        }
      },
      index: {
        type: Number,
        default: ()=> {
          return 0
        }
      }
    },
    data() {
      return {
        common
      }
    },
    methods: {
      getAlignment() {
        if (this.con.type==='user') {
          return 'justify-end'
        } else if(this.con.type==='bot') {
          return 'justify-start'
        }
      },
      getColor() {
        if (this.con.type==='user') {
          return 'botter-user-text'
        } else if(this.con.type==='bot') {
          return 'botter-bot-text'
        }
      }
    },
    computed: {
      lastMessageGroup () {
        return this.$store.state.messages.conversationList[this.index].lastMessageGroup
      }
    }
  }
</script>

<style lang="scss">
  .botter-map-card {
    width: 80%;
    margin-top: 10px;
    border-radius: 5px;
    background: #EAF0F7;
    overflow: hidden;
    .botter-map-card-desc {
      padding: 12px;
    }
    .botter-btn {
      background: white;
      border: none !important;
      color: inherit !important;
      display: block;
    }
  }

</style>
